interface Config {
  production: string
  baseUrl: string
  tenant: string
  version: string
  tokenUrl: string
  toolVersion: string
  platform: string
  mpKey: string
  reCaptchaKey: string
  relativePath: string
}

class ConfigService {
  public config: Config | null

  constructor() {
    this.config = null
  }

  async init() {
    const res = await fetch('/assets/config/config.json')
    const config = await res.json()

    this.config = config
  }

  getConfig() {
    return this.config
  }
}

export default new ConfigService()
