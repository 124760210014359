import { createTheme, MantineProvider } from '@mantine/core'
import '@mantine/core/styles.css'
import '@mantine/dates/styles.css'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import './index.scss'
import configService from './services/config'

const theme = createTheme({
  colors: {
    blue: [
      '#5570F1',
      '#5570F1',
      '#5570F1',
      '#5570F1',
      '#5570F1',
      '#5570F1',
      '#5570F1',
      '#5570F1',
      '#5570F1',
      '#5570F1'
    ]
  },
  primaryColor: 'blue'
})
;(async () => {
  await configService.init()
  const App = (await import('./App')).App

  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  )
  root.render(
    <MantineProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </MantineProvider>
  )
})()
